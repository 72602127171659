import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: center center;
  background-size: cover;
  height: 14rem;

  a.over-link {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--gray);
    opacity: 0;
    &:hover {
      opacity: .3;
    }

  }

  h3 {
    margin: 0;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-size: 1.9rem;
    font-family: RalewaySemiBold;
    border-left: 6px solid white;
    padding-left: .8rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .7);
    &:hover {
      opacity: 1;
    }
  }

`;

const GoCounty = ({fluid, url, county}) => {
  return (
    <StyledBackgroundImage Tag="div" fluid={fluid}>
      <Link to={url} className="over-link">
      </Link>
      <Link to={url}>
        <h3>{county}<br />County</h3>
      </Link>
    </StyledBackgroundImage>
  )
}

export default GoCounty;

