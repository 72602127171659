import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import GoCounty from "./go-county"

const StyledCounties = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .5rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const GoCounties = () => {
  const data = useStaticQuery(graphql`
    query {
      cameron: file(relativePath: { eq: "Emporium_Pennsylvania_Courthouse_View.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      clearfield: file(relativePath: { eq: "clearfield-sledriding.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      elk: file(relativePath: { eq: "Elk.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      jefferson: file(relativePath: { eq: "752202069710177517_kyle_yates-appreciation_of_beauty-clear_creek_jeffersonco.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      mckean: file(relativePath: { eq: "kinzua-skywalk-summer-1665.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      potter: file(relativePath: { eq: "night-sky.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <StyledCounties>
      <GoCounty fluid={data.cameron.sharp.fluid} url="/counties/cameron" county="Cameron" />
      <GoCounty fluid={data.clearfield.sharp.fluid} url="/counties/clearfield" county="Clearfield" />
      <GoCounty fluid={data.elk.sharp.fluid} url="/counties/elk" county="Elk" />
      <GoCounty fluid={data.jefferson.sharp.fluid} url="/counties/jefferson" county="Jefferson" />
      <GoCounty fluid={data.mckean.sharp.fluid} url="/counties/mckean" county="McKean" />
      <GoCounty fluid={data.potter.sharp.fluid} url="/counties/potter" county="Potter" />

    </StyledCounties>
  );
};

export default GoCounties;
