import React from "react";
import { graphql } from "gatsby";
import GoCounties from "../components/go-counties";
import HeroPage from "../components/hero-page";
import Seo from "../components/seo";

const Live = ({ data }) => {
  return (
    <>
      <Seo
        title="Live in PA Six, North Central Pennsylvania"
        description="Living in Cameron, Clearfield, Elk, Jefferson, McKean, Potter Counties of North Central Pennsylvania"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Live in PA Six"
        caption="Downtown DuBois - photo by Vandervort Photography"
        captionColor="black"
        position="30%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Living in North Central Pennsylvania</h2>
            <p>
              Economic vibrancy, good health care, excellent schools, affordable
              houses, and a high quality of life.
            </p>
          </div>

          <div className="intro-text">
            <p>
              North Central Pennsylvania has an abundance of flora and fauna, in
              addition to its cultural diversity. The region is mostly
              mountainous as it is located in the northern part of the
              Appalachian Mountain range. There are few large cities in North
              Central Pennsylvania, most of its population live in smaller
              boroughs or townships (populations between 5,000-15,000) than in
              large cities.
            </p>
          </div>
        </section>

        <section>
          <GoCounties />
        </section>
      </main>
    </>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "live-dubois-kelly.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "live-dubois-kelly.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default Live;
